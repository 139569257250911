import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api, api_delete } from '../services/api.service'

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

export default function AdminCars() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/car`).then((x) => setData(x))
    }, [])

    function deleteConfirm() {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/car/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/car`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

            <PageHeader headline={'Cars'} actions={<Button text='Add Car' onClick={() => navigate('/admin/manage-car/new')}></Button>}></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <Table
                            data={data.map((x) => {
                                return {
                                    ...x, name: x.make + ' ' + x.model,
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-car/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}
