import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/error-boundary'


import AdminCars from './admin/admin-cars'
import AdminUsers from './admin/admin-users'
import AdminLayout from './admin/layout'
import About from './app/about'
import Car from './app/car'
import Cars from './app/cars'
import Home from './app/home'
import AppLayout from './app/layout'
import Questionnaire from './app/questionnaire'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import AboutPageForm from './forms/about-page-form'
import CarForm from './forms/car-form'
import UserForm from './forms/user-form'

export default function App() {
    return (
        <ErrorBoundary componentName='App (top level catch)'>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/logout' element={<Logout />} />

                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Home />} />
                    <Route path='/our-electrifying-range' element={<Cars />} />
                    <Route path='/car/:id' element={<Car />} />
                    <Route path='/find-the-ev-for-me' element={<Questionnaire />} />
                    <Route path='/about' element={<About />} />
                </Route>

                <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<AdminUsers />} />
                    <Route path='/admin/manage-user/:id' element={<UserForm />} />
                    <Route path='/admin/cars' element={<AdminCars />} />
                    <Route path='/admin/manage-car/:id' element={<CarForm />} />
                    <Route path='/admin/about' element={<AboutPageForm />} />
                </Route>
            </Routes>
        </ErrorBoundary>
    )
}
