import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { PasswordField, TextField } from './fields'
import FormWrapper from './form-wrapper'

export default function UserForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage User'></PageHeader>
            <FormWrapper url='admin/user' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextField label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                        <PasswordField label='New Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
