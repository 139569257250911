import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../services/api.service'

export default function Home() {
    const navigate = useNavigate()
    const [data, setData] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setData(x))
    }, [])

    return (
        <>
            <Hero></Hero>
        </>
    )
}

function Hero() {
    const navigate = useNavigate()
    return (
        <div className="flex-1 py-32 overflow-hidden ">
            <div className="flex justify-center mx-auto">
                <div className="flex flex-col items-center">
                    <img src='/logo-invert.svg' className="lg:w-[48rem] mx-auto"></img>
                    <p className="lg:w-[48rem] mt-6 text-5xl font-semibold leading-none tracking-wide text-black text-center">
                        Discover the world of electric vehicles with <img src="./logo-invert.svg" className="relative inline ml-3 h-28 top-6"/>
                    </p>
                    <div className="flex flex-col mt-10 sm:flex-row">
                        <div onClick={() => navigate('/find-the-ev-for-me')}
                            className="w-64 px-5 py-3 text-xl font-medium tracking-wide text-center bg-white shadow-sm cursor-pointer text-neutral-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Find the EV for me
                        </div>
                        <div onClick={() => navigate('/our-electrifying-range')}
                            className="w-64 px-5 py-3 mt-5 text-xl font-medium tracking-wide text-center bg-white shadow-sm cursor-pointer sm:mt-0 sm:ml-5 text-neutral-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Our Electrifying Range
                        </div>
                    </div>
                </div>
                <span className='fixed px-6 py-2 bg-white rounded-full cursor-pointer bottom-5 right-5'>Live Chat</span>
            </div>
        </div>
    )
}
