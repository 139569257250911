import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { FunnelIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import ImageViewer from '../components/image-viewer'
import { api } from '../services/api.service'

export default function Cars() {
    const navigate = useNavigate()
    const [data, setData] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setData(x))
    }, [])
    if (!data) return <></>
    return <Products cars={data.cars}></Products>
}


const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
    { name: 'Price: Low to High', href: '#', current: false },
    { name: 'Price: High to Low', href: '#', current: false },
]

const filters = [
    {
        id: 'range',
        name: 'Range',
        options: [
            { value: '0', label: 'Up to 100 Miles', checked: false },
            { value: '100', label: '101-200 Miles', checked: false },
            { value: '200', label: '201-300 Miles', checked: false },
            { value: '300', label: '300+ Miles', checked: false }
        ],
    },
    {
        id: 'doors',
        name: 'Doors',
        options: [
            { value: '3', label: '3', checked: false },
            { value: '5', label: '5', checked: false },
        ],
    },
    {
        id: 'drive',
        name: 'Drive',
        options: [
            { value: 'FWD', label: 'FWD', checked: false },
            { value: 'RWD', label: 'RWD', checked: false },
            { value: 'AWD', label: 'AWD', checked: false }
        ],
    },
]
const products = [
    {
        id: 1,
        name: 'Nomad Pouch',
        href: '#',
        price: '$50',
        availability: 'White and Black',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-07-product-01.jpg',
        imageAlt: 'White fabric pouch with white zipper, black zipper pull, and black elastic loop.',
    },
    {
        id: 2,
        name: 'Zip Tote Basket',
        href: '#',
        price: '$140',
        availability: 'Washed Black',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-07-product-02.jpg',
        imageAlt: 'Front of tote bag with washed black canvas body, black straps, and tan leather handles and accents.',
    },
    // More products...
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Products({ cars }) {
    const navigate = useNavigate();
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

    const [filterData, setFilterData] = useState({})

    function getFilteredData() {
        return cars.filter(car => {
            let matchRange = false;
            let matchDoors = false;
            let matchDrive = false
            let filterOnRange = false
            let filterOnDoors = false
            let filterOnDrive = false
            for (let key in filterData) {
                if (key.includes("range-") && filterData[key]) {
                    filterOnRange = true
                    let minRange = parseInt(key.split("-")[1]);
                    if (car.range > minRange) {
                        matchRange = true;
                        break;
                    }
                } else if (key.includes("doors-") && filterData[key]) {
                    filterOnDoors = true
                    let numDoors = parseInt(key.split("-")[1]);
                    if (car.doors == numDoors) {
                        matchDoors = true;
                        break;
                    }
                } else if (key.includes("drive-") && filterData[key]) {
                    filterOnDrive = true
                    let drive = key.split("-")[1];
                    if (car.drive === drive) {
                        matchDrive = true;
                        break;
                    }
                }
            }

            return (!filterOnRange || matchRange) && (!filterOnDoors || matchDoors) && (!filterOnDrive || matchDrive)
        });
    }

    return (
        <div>
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="relative flex flex-col w-full h-full max-w-xs py-4 pb-12 ml-auto overflow-y-auto bg-white shadow-xl">
                                <div className="flex items-center justify-between px-4">
                                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                    <button
                                        type="button"
                                        className="relative flex items-center justify-center w-10 h-10 p-2 -mr-2 text-gray-400 bg-white rounded-md"
                                        onClick={() => setMobileFiltersOpen(false)}
                                    >
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                                    </button>
                                </div>

                                {/* Filters */}
                                <form className="mt-4 border-t border-black">

                                    {filters.map((section) => (
                                        <Disclosure as="div" key={section.id} className="px-4 py-6 border-t border-black">
                                            {({ open }) => (
                                                <>
                                                    <h3 className="flow-root -mx-2 -my-3">
                                                        <Disclosure.Button className="flex items-center justify-between w-full px-2 py-3 text-gray-400 bg-white hover:text-gray-500">
                                                            <span className="font-medium text-gray-900">{section.name}</span>
                                                            <span className="flex items-center ml-6">
                                                                {open ? (
                                                                    <MinusIcon className="w-5 h-5" aria-hidden="true" />
                                                                ) : (
                                                                    <PlusIcon className="w-5 h-5" aria-hidden="true" />
                                                                )}
                                                            </span>
                                                        </Disclosure.Button>
                                                    </h3>
                                                    <Disclosure.Panel className="pt-6">
                                                        <div className="space-y-6">
                                                            {section.options.map((option, optionIdx) => (
                                                                <div key={option.value} className="flex items-center">
                                                                    <input
                                                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                                                        name={`${section.id}[]`}
                                                                        defaultValue={option.value}
                                                                        type="checkbox"
                                                                        defaultChecked={option.checked}
                                                                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                                    />
                                                                    <label
                                                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                                                        className="flex-1 min-w-0 ml-3 text-gray-500"
                                                                    >
                                                                        {option.label}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    ))}
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <main className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex items-baseline justify-between pt-24 pb-6 border-b border-black">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900">Our Electrifying Range</h1>

                    <div className="flex items-center">


                        <button
                            type="button"
                            className="p-2 ml-4 -m-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                            onClick={() => setMobileFiltersOpen(true)}
                        >
                            <span className="sr-only">Filters</span>
                            <FunnelIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>

                <section aria-labelledby="products-heading" className="pt-6 pb-24">
                    <h2 id="products-heading" className="sr-only">
                        Products
                    </h2>

                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-5">
                        {/* Filters */}
                        <form className="hidden lg:block">


                            {filters.map((section) => (
                                <Disclosure as="div" key={section.id} className="py-6 border-b border-black">
                                    {({ open }) => (
                                        <>
                                            <h3 className="flow-root -my-3">
                                                <Disclosure.Button className="flex items-center justify-between w-full py-3 text-sm text-black">
                                                    <span className="font-medium text-black">{section.name}</span>
                                                    <span className="flex items-center ml-6">
                                                        {open ? (
                                                            <MinusIcon className="w-5 h-5" aria-hidden="true" />
                                                        ) : (
                                                            <PlusIcon className="w-5 h-5" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </h3>
                                            <Disclosure.Panel className="pt-6">
                                                <div className="space-y-4">
                                                    {section.options.map((option, optionIdx) => (
                                                        <div key={option.value} className="flex items-center">
                                                            <input
                                                                id={`filter-${section.id}-${optionIdx}`}
                                                                name={`${section.id}[]`}
                                                                type="checkbox"
                                                                checked={filterData[`${section.id}-${option.label}`]}
                                                                onChange={(e) => setFilterData({ ...filterData, [`${section.id}-${option.label}`]: e.target.checked })}
                                                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                            />
                                                            <label
                                                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                                                className="ml-3 text-sm text-black"
                                                            >
                                                                {option.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </form>

                        <div className="lg:col-span-4 ">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 lg:gap-x-8">
                                {getFilteredData().map((product) => (
                                    <div key={product._id} className="relative text-sm bg-white rounded-lg shadow group">
                                        <img className="absolute inset-0 w-full h-full p-5 pointer-events-none -top-8" src="/icon-green.svg"></img>
                                        <div className="w-full overflow-hidden aspect-h-1 aspect-w-1">
                                            <ImageViewer
                                                image={product.thumbnail}
                                                alt={product.model}
                                                className="object-scale-down object-center w-full h-full "
                                            />
                                        </div>

                                        <div className="px-5 pb-5">
                                            <div className="relative flex items-end justify-between -top-5">
                                                <div>
                                                    <h4 className="text-lg font-bold text-gray-900">{product.make}</h4>
                                                    <h3 className="font-medium text-gray-700">{product.model}</h3>
                                                    <p className="italic text-gray-500">{product.range} miles</p>
                                                </div>
                                                <div className="relative">
                                                    <div className="relative text-xs text-gray-500 top-2">from</div>

                                                    <div>
                                                        <span className="text-3xl font-bold text-gray-900">£{product.monthlyPriceFrom}</span> <span className="text-gray-700">/mo</span>

                                                    </div>

                                                </div>
                                            </div>

                                            <button
                                                onClick={() => navigate('/car/' + product._id)}
                                                type="button"
                                                className="w-full px-3 py-2 font-light tracking-wide text-white rounded-md shadow-sm cursor-pointer hover:bg-neutral-900 text-md bg-neutral-800"
                                            >
                                                Explore
                                            </button>


                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </section>
            </main>

        </div>
    )
}
