import MultiRangeSlider from "multi-range-slider-react";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ImageViewer from '../components/image-viewer';
import { api } from '../services/api.service';

export default function Questionnaire() {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [filteredData, setFilteredData] = useState()

    const [values, setValues] = useState({ bodyStyle: [] })
    const [step, setStep] = useState(0)
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setData(x))
    }, [])

    const applyFilters = (cars, values) => {
        let returnedData = cars

        returnedData = returnedData.filter(car => values.bodyStyle.includes(car.bodyStyle.toLowerCase().trim()))

        returnedData = returnedData.filter(car => {
            return +car.range >= +values.range.from && (!values.range.to || +car.range <= values.range.to)
        })

        if (values.buy === 'monthly') {
            returnedData = returnedData.filter(car => {
                return +car.monthlyPriceFrom >= values.budget.from && (!values.budget.to || +car.monthlyPriceFrom <= values.budget.to)
            })
        }

        if (values.buy === 'outright') {
            returnedData = returnedData.filter(car => {
                return +car.outrightPrice >= values.budget.from && (!values.budget.to || +car.outrightPrice <= values.budget.to)
            })
        }

        return setFilteredData(returnedData)
    }

    return (
        <>
            <div className="w-full max-w-6xl p-10 mx-auto mt-10">
                {step > 0 && <button className="float-right px-5 py-3 mx-auto text-xl font-medium tracking-wide text-black bg-white shadow-sm cursor-pointer hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" onClick={() => { setValues({ bodyStyle: [] }); setStep(0) }}>Start Over</button>}
                {step === 0 && <>
                    <div className="my-5 text-5xl font-extrabold tracking-tight text-center text-black">Find the EV for me with <img src="./logo-invert.svg" className="relative inline ml-3 h-28 top-6" /></div>
                    <div className="font-medium text-center text-black">Click the button below to begin...</div>
                    <div className="flex mt-10">
                        <div onClick={() => setStep(1)}
                            className="px-5 py-3 mx-auto text-xl font-medium tracking-wide text-black bg-white shadow-sm cursor-pointer hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Get Started
                        </div>
                    </div>

                </>}

                {step === 1 && <>
                    <div className="my-5 text-2xl font-medium text-black">What size of EV do you need?</div>
                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
                        <div onClick={() => setValues({ ...values, bodyStyle: values.bodyStyle.includes('small') ? values.bodyStyle.filter(x => x != 'small') : values.bodyStyle.concat(['small']) })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 transition p-5 ${values.bodyStyle.includes('small') ? 'bg-white/50 text-black' : ''} `}><img src='/1.png' />Small 3-door</div>
                        <div onClick={() => setValues({ ...values, bodyStyle: values.bodyStyle.includes('hatchback') ? values.bodyStyle.filter(x => x != 'hatchback') : values.bodyStyle.concat(['hatchback']) })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 transition p-5 ${values.bodyStyle.includes('hatchback') ? 'bg-white/50 text-black' : ''} `}><img src='/3.png' />Hatch Back 5-door</div>
                        <div onClick={() => setValues({ ...values, bodyStyle: values.bodyStyle.includes('large') ? values.bodyStyle.filter(x => x != 'large') : values.bodyStyle.concat(['large']) })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 transition p-5 ${values.bodyStyle.includes('large') ? 'bg-white/50 text-black' : ''} `}><img src='/2.png' />Large 5-door</div>
                        <div onClick={() => setValues({ ...values, bodyStyle: values.bodyStyle.includes('suv') ? values.bodyStyle.filter(x => x != 'suv') : values.bodyStyle.concat(['suv']) })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 transition p-5 ${values.bodyStyle.includes('suv') ? 'bg-white/50 text-black' : ''} `}><img src='/5.png' />SUV 5-door</div>
                    </div>

                    {values.bodyStyle &&
                        <div className="flex mt-10">
                            <div onClick={() => setStep(2)}
                                className="px-5 py-3 mx-auto text-xl font-medium tracking-wide text-black bg-white shadow-sm cursor-pointer hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Next
                            </div>
                        </div>}
                </>}

                {step === 2 && <>
                    <div className="my-5 text-2xl font-medium text-black">Estimated weekly mileage</div>

                    <MultiRangeSlider
                        className="my-5"
                        ruler={false}
                        style={{ border: 'none', boxShadow: 'none' }}
                        min={0}
                        max={500}
                        step={5}
                        minValue={values.range?.from ?? 0}
                        maxValue={values.range?.to ?? 60000}
                        onInput={(e) => {
                            setValues({ ...values, range: { from: e.minValue, to: e.maxValue } })
                        }}
                    />

                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
                        <div onClick={() => setValues({ ...values, range: { from: 0, to: 100 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-5 shadow bg-white ${values.range?.from == 0 && values?.range.to == 100 ? 'bg-white/50 text-black' : ''} `}>Up To 100 Miles</div>
                        <div onClick={() => setValues({ ...values, range: { from: 100, to: 200 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-5 shadow bg-white ${values.range?.from == 100 && values?.range.to == 200 ? 'bg-white/50 text-black' : ''} `}>101-200 Miles</div>
                        <div onClick={() => setValues({ ...values, range: { from: 200, to: 300 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-5 shadow bg-white ${values.range?.from == 200 && values?.range.to == 300 ? 'bg-white/50 text-black' : ''} `}>201-300 Miles</div>
                        <div onClick={() => setValues({ ...values, range: { from: 300, to: 500 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-5 shadow bg-white ${values.range?.from == 300 && values?.range.to == 500 ? 'bg-white/50 text-black' : ''} `}>300+ Miles</div>
                    </div>

                    {values.range !== undefined &&
                        <div className="flex mt-10">
                            <div onClick={() => setStep(3)}
                                className="px-5 py-3 mx-auto text-xl font-medium tracking-wide text-black bg-white shadow-sm cursor-pointer hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Next
                            </div>
                        </div>}
                </>}



                {step === 3 && <>
                    <div className="my-5 text-2xl font-medium text-black">Pay monthly or buy outright?</div>
                    <div className="flex justify-between mb-5 space-x-4">
                        <div onClick={() => setValues({ ...values, buy: 'monthly', budget: { from: 200, to: 750 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-5 shadow bg-white ${values.buy == 'monthly' ? 'bg-white/50 text-black' : ''} `}>Monthly</div>
                        <div onClick={() => setValues({ ...values, buy: 'outright', budget: { from: 0, to: 60000 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-5 shadow bg-white ${values.buy == 'outright' ? 'bg-white/50 text-black' : ''} `}>Purchase</div>
                    </div>

                    {values.buy == 'monthly' && <>

                        <div>
                            <MultiRangeSlider
                                ruler={false}
                                style={{ border: 'none', boxShadow: 'none' }}
                                className="my-5"
                                min={200}
                                max={750}
                                step={0}
                                minValue={values.budget?.from ?? 200}
                                maxValue={values.budget?.to ?? 750}
                                onInput={(e) => {
                                    setValues({ ...values, budget: { from: e.minValue, to: e.maxValue } })
                                }}
                            />
                        </div>


                        <div className="grid grid-cols-1 gap-4 text-sm sm:grid-cols-4">
                            <div onClick={() => setValues({ ...values, budget: { from: 200, to: 300 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 200 ? 'bg-white/50 text-black' : ''} `}>£200 - £300</div>
                            <div onClick={() => setValues({ ...values, budget: { from: 301, to: 500 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 301 ? 'bg-white/50 text-black' : ''} `}>£301 - £500</div>
                            <div onClick={() => setValues({ ...values, budget: { from: 501, to: 750 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 501 ? 'bg-white/50 text-black' : ''} `}>£501 - £750</div>
                            <div onClick={() => setValues({ ...values, budget: { from: 750 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 750 ? 'bg-white/50 text-black' : ''} `}>£750+</div>
                        </div>
                    </>}
                    {values.buy == 'outright' && <>

                        <MultiRangeSlider
                            className="my-5"
                            ruler={false}
                            style={{ border: 'none', boxShadow: 'none' }}
                            min={0}
                            max={60000}
                            step={500}
                            minValue={values.budget?.from ?? 0}
                            maxValue={values.budget?.to ?? 60000}
                            onInput={(e) => {
                                setValues({ ...values, budget: { from: e.minValue, to: e.maxValue } })
                            }}
                        />

                        <div className="grid grid-cols-1 gap-4 text-sm sm:grid-cols-4">
                            <div onClick={() => setValues({ ...values, budget: { from: 0, to: 30000 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 15000 ? 'bg-white/50 text-black' : ''} `}>Up to £30,000</div>
                            <div onClick={() => setValues({ ...values, budget: { from: 30000, to: 45000 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 30000 ? 'bg-white/50 text-black' : ''} `}>£30,000 - £45,000</div>
                            <div onClick={() => setValues({ ...values, budget: { from: 45000, to: 60000 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 45000 ? 'bg-white/50 text-black' : ''} `}>£45,000 - £60,000</div>
                            <div onClick={() => setValues({ ...values, budget: { from: 60000 } })} className={`flex-1 text-center cursor-pointer select-none hover:scale-105 hover:shadow-lg transition p-3 shadow bg-white ${values.budget?.from == 60000 ? 'bg-white/50 text-black' : ''} `}>£60,000+</div>
                        </div>
                    </>}

                    {values.budget &&
                        <div className="flex mt-10">
                            <div onClick={() => {
                                setStep(4)
                                applyFilters(data.cars, values)
                            }}
                                className="px-5 py-3 mx-auto text-xl font-medium tracking-wide text-black bg-white shadow-sm cursor-pointer hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Next
                            </div>
                        </div>}
                </>}

                {step === 4 && <>

                    <div className="my-10 text-2xl font-medium text-center text-black">We've found your matches! Browse the EVs for you:</div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 lg:gap-x-8">

                        {filteredData && filteredData.map((product) => (
                            <div key={product._id} className="relative text-sm bg-white rounded-lg shadow group">
                                <img className="absolute inset-0 w-full h-full p-5 pointer-events-none -top-8" src="/icon-green.svg"></img>
                                <div className="w-full overflow-hidden aspect-h-1 aspect-w-1">
                                    <ImageViewer
                                        image={product.thumbnail}
                                        alt={product.model}
                                        className="object-scale-down object-center w-full h-full"
                                    />
                                </div>

                                <div className="px-5 pb-5">
                                    <div className="relative flex items-end justify-between -top-5">
                                        <div>
                                            <h4 className="text-lg font-bold text-gray-900">{product.make}</h4>
                                            <h3 className="font-medium text-gray-700">{product.model}</h3>
                                            <p className="italic text-gray-500">{product.range} miles</p>
                                        </div>
                                        <div className="relative">
                                            <div className="relative text-xs text-gray-500 top-2">from</div>

                                            <div>
                                                <span className="text-3xl font-bold text-gray-900">£{product.monthlyPriceFrom}</span> <span className="text-gray-700">/mo</span>

                                            </div>

                                        </div>
                                    </div>

                                    <button
                                        onClick={() => navigate('/car/' + product._id)}
                                        type="button"
                                        className="w-full px-3 py-2 font-light tracking-wide text-white rounded-md shadow-sm cursor-pointer hover:bg-neutral-900 text-md bg-neutral-800"
                                    >
                                        Explore
                                    </button>


                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="max-w-2xl mx-auto mt-24 sm:mt-32 lg:max-w-none">
                        <div className="flex items-center justify-between space-x-4">
                            <h2 className="text-lg font-medium text-gray-900">Other cars you may like</h2>
                            <Link to={'/our-electrifying-range'} className="text-sm font-medium text-black whitespace-nowrap hover:text-green-700">
                                View all
                                <span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                        <div className="grid grid-cols-1 mt-6 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                            {data.cars.filter((x, i) => i < 4).map((product) => (
                                <div key={product._id} className="relative text-sm bg-white rounded-lg shadow group">
                                    <img className="absolute inset-0 w-full h-full p-5 pointer-events-none -top-8" src="/icon-green.svg"></img>
                                    <div className="w-full overflow-hidden aspect-h-1 aspect-w-1">
                                        <ImageViewer
                                            image={product.thumbnail}
                                            alt={product.model}
                                            className="object-scale-down object-center w-full h-full "
                                        />
                                    </div>

                                    <div className="px-5 pb-5">
                                        <div className="relative flex items-end justify-between -top-5">
                                            <div>
                                                <h4 className="text-lg font-bold text-gray-900">{product.make}</h4>
                                                <h3 className="font-medium text-gray-700">{product.model}</h3>
                                                <p className="italic text-gray-500">{product.range} miles</p>
                                            </div>
                                            <div className="relative">
                                                <div className="relative text-xs text-gray-500 top-2">from</div>

                                                <div>
                                                    <span className="text-3xl font-bold text-gray-900">£{product.monthlyPriceFrom}</span> <span className="text-gray-700">/mo</span>

                                                </div>

                                            </div>
                                        </div>

                                        <button
                                            onClick={() => navigate('/car/' + product._id)}
                                            type="button"
                                            className="w-full px-3 py-2 font-light tracking-wide text-white rounded-md shadow-sm cursor-pointer hover:bg-neutral-900 text-md bg-neutral-800"
                                        >
                                            Explore
                                        </button>


                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </>}


            </div>

        </>
    )
}
