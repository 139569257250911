import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { TextAreaField, TextField } from './fields'
import ImageField from './fields/image-upload'
import FormWrapper from './form-wrapper'

export default function CarForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage Car'></PageHeader>
            <FormWrapper url='admin/car' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Make' value={values.make} onChange={(val) => setValues({ ...values, make: val })}></TextField>
                        <TextField label='Model' value={values.model} onChange={(val) => setValues({ ...values, model: val })}></TextField>
                        <TextAreaField label='Description' value={values.description} onChange={(val) => setValues({ ...values, description: val })}></TextAreaField>
                        <TextAreaField label='Key Features' value={values.keyFeatures} onChange={(val) => setValues({ ...values, keyFeatures: val })}></TextAreaField>
                        <TextAreaField label='Details' value={values.details} onChange={(val) => setValues({ ...values, details: val })}></TextAreaField>

                        <ImageField label='Thumbnail' value={values.thumbnail} onChange={(val) => setValues({ ...values, thumbnail: val })} />

                        <div>
                            {values.photos?.map((x, i) => <div>



                            </div>)}
                            <div onClick={() => setValues({ ...values, photos: values.photos ? values.photos.concat(['']) : [''] })}>Add Photo</div>
                        </div>



                        <TextField label='Body Style' value={values.bodyStyle} onChange={(val) => setValues({ ...values, bodyStyle: val })}></TextField>
                        <TextField label='Range' value={values.range} onChange={(val) => setValues({ ...values, range: val })}></TextField>
                        <TextField label='Drive' value={values.drive} onChange={(val) => setValues({ ...values, drive: val })}></TextField>
                        <TextField label='Doors' value={values.doors} onChange={(val) => setValues({ ...values, doors: val })}></TextField>
                        <TextField label='Monthly Price From' value={values.monthlyPriceFrom} onChange={(val) => setValues({ ...values, monthlyPriceFrom: val })}></TextField>
                        <TextField label='Outright Purchase Price' value={values.outrightPrice} onChange={(val) => setValues({ ...values, outrightPrice: val })}></TextField>

                        {/* {values.dealers?.map((x, i) => <div className="p-5 my-3 border rounded-lg">

                            <TextField label='Dealer Name' value={x.name} onChange={(val) => setValues({ ...values, dealers: values.dealers.map((y, j) => j === i ? { ...y, name: val } : y) })}></TextField>
                            <TextField label='URL' value={x.url} onChange={(val) => setValues({ ...values, dealers: values.dealers.map((y, j) => j === i ? { ...y, url: val } : y) })}></TextField>
                            <TextField label='Price' value={x.price} onChange={(val) => setValues({ ...values, dealers: values.dealers.map((y, j) => j === i ? { ...y, price: val } : y) })}></TextField>
                            <TextField label='Purchase Options' value={x.purchaseOptions} onChange={(val) => setValues({ ...values, dealers: values.dealers.map((y, j) => j === i ? { ...y, purchaseOptions: val } : y) })}></TextField>

                        </div>)}
                        <div className="my-6 text-neutral-600" onClick={() => setValues({ ...values, dealers: values.dealers ? values.dealers.concat([{}]) : [{}] })} >+ Add Dealer</div> */}

                    </>
                )}
            </FormWrapper>
        </div>
    )
}
