import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { TextAreaField, TextField } from './fields'
import FormWrapper from './form-wrapper'

export default function AboutPageForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage About Page'></PageHeader>
            <FormWrapper url='admin/about-page' id={'single'} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Title' value={values.data?.title} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, title: val } : { title: val } })}></TextField>
                        <TextAreaField label='Bio' value={values.data?.bio} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, bio: val } : { bio: val } })}></TextAreaField>


                        <TextField label='Heading 1' value={values.data?.subtitle} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, subtitle: val } : { subtitle: val } })}></TextField>
                        <TextAreaField label='Body 1' value={values.data?.body} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, body: val } : { body: val } })}></TextAreaField>

                        <TextField label='Heading 2' value={values.data?.subtitle2} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, subtitle2: val } : { subtitle2: val } })}></TextField>
                        <TextAreaField label='Body 2' value={values.data?.body2} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, body2: val } : { body2: val } })}></TextAreaField>

                        <TextField label='Heading 3' value={values.data?.subtitle3} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, subtitle3: val } : { subtitle3: val } })}></TextField>
                        <TextAreaField label='Body 3' value={values.data?.body3} onChange={(val) => setValues({ ...values, data: values.data ? { ...values.data, body3: val } : { body3: val } })}></TextAreaField>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
