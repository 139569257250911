import { useState } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


export default function AppLayout() {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col min-h-screen bg-white'>
            <AppHeader></AppHeader>

            <div className='flex flex-col flex-1 bg-green-500'>
                <Outlet></Outlet>
            </div>
        </div>
    )
}
export const NAV_LINKS = [{ text: 'Home', link: '/' }, { text: 'Find the EV for me', link: '/find-the-ev-for-me' }, { text: 'Our Electrifying Range', link: '/our-electrifying-range' }, { text: 'About Us', link: '/about' }]

function AppHeader() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    return (
        <div className='sticky top-0 left-0 right-0 z-50 flex px-6 py-5 bg-white shadow-md select-none'>
            <div className="flex items-center justify-between w-full mx-auto">
                {/* Brand */}
                <div className='flex items-center cursor-pointer' onClick={() => navigate('/')}>
                    <img src='/logo.svg' className="w-36" alt={"Electrify Me"} />
                </div>

                {/* Header Nav */}
                <div className='hidden text-center sm:block'>
                    <nav className='flex items-center space-x-6'>

                        {NAV_LINKS.map((d) => (
                            <NavLink key={d.link} to={d.link} className='text-lg font-bold text-neutral-700 hover:text-green-700'>
                                {d.text}
                            </NavLink>
                        ))}
                    </nav>
                </div>
                {/* Sidebar Nav */}
                <div className='block sm:hidden'>
                    <div className='cursor-pointer' onClick={() => setOpen(true)}>
                        <Bars3Icon className='w-8 h-8' />
                    </div>
                    {open && (
                        <div className='fixed top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.2)]' onClick={() => setOpen(false)}>
                            <div className='fixed top-0 bottom-0 right-0 flex flex-col px-6 py-2 space-y-10 bg-white border-l shadow-2xl min-w-fit' onClick={(e) => e.stopPropagation()}>
                                <div className='flex justify-end w-full'>
                                    <div className='cursor-pointer h-[72px] flex' onClick={() => setOpen(false)}>
                                        <XMarkIcon className='w-8 h-8 my-auto' />
                                    </div>
                                </div>
                                <nav className='flex flex-col items-end px-6 space-y-4'>
                                    {NAV_LINKS.map((d) => (
                                        <NavLink key={d.link} to={d.link} className='font-bold text-neutral-700 hover:text-green-700'>
                                            {d.text}
                                        </NavLink>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}