import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Tab } from '@headlessui/react'
import { Fragment } from 'react'
import ImageViewer from '../components/image-viewer'
import { api } from '../services/api.service'


export default function Car() {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [allData, setAllData] = useState()
    const params = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
        api(`${process.env.REACT_APP_API_URL}/app/car/${params.id}`).then((x) => setData(x))
    }, [params.id])

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setAllData(x))
    }, [])

    if (!data || !allData) return <></>
    return <Product car={data.car} data={allData}></Product>
}



const product = {
    name: 'Application UI Icon Pack',
    version: { name: '1.0', date: 'June 5, 2021', datetime: '2021-06-05' },
    price: '$220',
    description:
        'The Application UI Icon Pack comes with over 200 icons in 3 styles: outline, filled, and branded. This playful icon pack is tailored for complex application user interfaces with a friendly and legible look.',
    highlights: [
        '200+ SVG icons in 3 unique styles',
        'Compatible with Figma, Sketch, and Adobe XD',
        'Drawn on 24 x 24 pixel grid',
    ],
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-05-product-01.jpg',
    imageAlt: 'Sample of 30 icons with friendly and fun details in outline, filled, and brand color styles.',
}
const reviews = {
    average: 4,
    featured: [
        {
            id: 1,
            rating: 5,
            content: `
        <p>This icon pack is just what I need for my latest project. There's an icon for just about anything I could ever need. Love the playful look!</p>
      `,
            date: 'July 16, 2021',
            datetime: '2021-07-16',
            author: 'Emily Selman',
            avatarSrc:
                'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
        },
        {
            id: 2,
            rating: 5,
            content: `
        <p>Blown away by how polished this icon pack is. Everything looks so consistent and each SVG is optimized out of the box so I can use it directly with confidence. It would take me several hours to create a single icon this good, so it's a steal at this price.</p>
      `,
            date: 'July 12, 2021',
            datetime: '2021-07-12',
            author: 'Hector Gibbons',
            avatarSrc:
                'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
        },
        // More reviews...
    ],
}
const faqs = [
    {
        question: 'What format are these icons?',
        answer:
            'The icons are in SVG (Scalable Vector Graphic) format. They can be imported into your design tool of choice and used directly in code.',
    },
    {
        question: 'Can I use the icons at different sizes?',
        answer:
            "Yes. The icons are drawn on a 24 x 24 pixel grid, but the icons can be scaled to different sizes as needed. We don't recommend going smaller than 20 x 20 or larger than 64 x 64 to retain legibility and visual balance.",
    },
    // More FAQs...
]
const license = {
    href: '#',
    summary:
        'For personal and professional use. You cannot resell or redistribute these icons in their original or modified state.',
    content: `
    <h4>Overview</h4>
    
    <p>For personal and professional use. You cannot resell or redistribute these icons in their original or modified state.</p>
    
    <ul role="list">
    <li>You\'re allowed to use the icons in unlimited projects.</li>
    <li>Attribution is not required to use the icons.</li>
    </ul>
    
    <h4>What you can do with it</h4>
    
    <ul role="list">
    <li>Use them freely in your personal and professional work.</li>
    <li>Make them your own. Change the colors to suit your project or brand.</li>
    </ul>
    
    <h4>What you can\'t do with it</h4>
    
    <ul role="list">
    <li>Don\'t be greedy. Selling or distributing these icons in their original or modified state is prohibited.</li>
    <li>Don\'t be evil. These icons cannot be used on websites or applications that promote illegal or immoral beliefs or activities.</li>
    </ul>
  `,
}
const relatedProducts = [
    {
        id: 1,
        name: 'Fusion',
        category: 'UI Kit',
        href: '#',
        price: '$49',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-05-related-product-01.jpg',
        imageAlt:
            'Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background.',
    },
    // More products...
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PURCHASE_OPTIONS = [{
    name: "Outright Purchase",
    description: "This method involves paying the full purchase price of the car upfront, either in cash or through financing. The buyer immediately becomes the owner of the vehicle once the payment is made.",
    pros: ["Full ownership of the car", "No ongoing monthly payments", "No restrictions or limitations on mileage, modifications, or usage"],
    cons: ["Requires a significant upfront payment", "Depreciation of the car's value over time", "Responsibility for maintenance and repair costs"]
},
{
    name: "Financing",
    description: "With financing, the consumer borrows money from a bank or financial institution to purchase the car, and then repays the loan with interest over a set period of time.",
    pros: ["Allows for spreading the cost of the car over monthly payments.", "Helps build credit if payments are made on time.", "Ownership of the car once the loan is fully repaid."],
    cons: ["Interest charges increase the overall cost of the vehicle.", "Monthly payments may strain the budget.", "The car acts as collateral, which can be repossessed if loan payments are not made."],
},
{
    name: "Personal Lease",
    description: "Leasing involves paying for the use of the car for a set period, typically 2-4 years, rather than buying it outright. The lessee returns the car at the end of the lease term.",
    pros: ["Lower monthly payments compared to financing.", "Option to drive a new car with the latest features every few years.", "Limited responsibility for major repairs and maintenance."],
    cons: ["No ownership of the vehicle.", "Mileage restrictions and fees for exceeding them.", "Additional charges for excessive wear and tear."],
},

]

function Product({ car, data }) {
    const navigate = useNavigate();
    const [purchaseTab, setPurchaseTab] = useState(0)
    const tabRef = useRef();
    const [selectedIndex, setSelectedIndex] = useState(0)
    return (
        <div>


            <main className="px-4 pb-24 mx-auto pt-14 sm:px-6 sm:pb-32 sm:pt-16 lg:max-w-7xl lg:px-8">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                    {/* Product image */}
                    <div className="lg:col-span-4 lg:row-end-1">
                        <div className="overflow-hidden bg-gray-100 rounded-lg aspect-h-3 aspect-w-4">
                            <ImageViewer image={car.thumbnail} alt={car.model} className="object-scale-down object-center" />
                        </div>
                    </div>

                    {/* Product details */}
                    <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                        <div className="flex flex-col-reverse">
                            <div className="mt-4">
                                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{car.model}</h1>

                                

                                <p className="mt-2 text-sm text-gray-500">
                                    from <span className="text-2xl font-bold text-black">£{car.monthlyPriceFrom}</span> Per Month
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                <span className="text-2xl font-bold text-black">£{car.outrightPrice}</span> Purchase
                                </p>
                                <p className="mt-3 text-sm text-gray-500">
                                    {car.range} Miles Range
                                </p>
                            </div>

                            {/* <div>
                                <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                        <StarIcon
                                            key={rating}
                                            className={classNames(
                                                reviews.average > rating ? 'text-yellow-400' : 'text-gray-300',
                                                'h-5 w-5 flex-shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                    ))}
                                </div>
                            </div> */}
                        </div>

                        <p className="mt-6 text-black">{car.description}</p>

                        <div className="grid grid-cols-1 mt-10 gap-x-6 gap-y-4 sm:grid-cols-2">
                            <button
                                onClick={() => { tabRef.current.scrollIntoView(); setSelectedIndex(0) }}
                                type="button"
                                className="flex items-center justify-center w-full px-8 py-3 text-sm font-medium text-white bg-green-700 border border-transparent hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                                View Purchase Options
                            </button>
                            <button
                                onClick={null}

                                type="button"
                                className="flex items-center justify-center w-full px-8 py-3 text-sm font-medium text-green-800 border border-transparent bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                                Book a test drive
                            </button>
                        </div>

                        <div className="pt-10 mt-10 border-t border-black">
                            <h3 className="text-sm font-medium text-gray-900">Key Features</h3>
                            <div className="mt-4 prose-sm prose text-black whitespace-pre-wrap">
                                {car.keyFeatures}
                            </div>
                        </div>

                    </div>

                    <div ref={tabRef} className="w-full max-w-2xl mx-auto mt-16 lg:col-span-4 lg:mt-0 lg:max-w-none">
                        <Tab.Group as="div" selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <div className="border-b border-black">
                                <Tab.List className="flex -mb-px space-x-8">
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                                selected
                                                    ? 'border-black text-black'
                                                    : 'border-transparent text-gray-700 hover:border-black hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                            )
                                        }
                                    >
                                        Purchase Options
                                    </Tab>
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                                selected
                                                    ? 'border-black text-black'
                                                    : 'border-transparent text-gray-700 hover:border-black hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                            )
                                        }
                                    >
                                        Details
                                    </Tab>
                                </Tab.List>
                            </div>
                            <Tab.Panels as={Fragment}>
                                <Tab.Panel className="-mb-10">

                                    <div className="flex mt-5">
                                        {PURCHASE_OPTIONS.map((x, i) => <div onClick={() => setPurchaseTab(i)} className={`${i == 0 ? 'rounded-l-xl' : i == 2 ? 'rounded-r-xl' : ''} select-none cursor-pointer text-center flex-1 p-5 bg-white ${purchaseTab == i ? 'bg-green-700 text-white' : ''}`}>{x.name}</div>)}

                                    </div>

                                    {PURCHASE_OPTIONS.filter((x, i) => i == purchaseTab).map(x => <div className="p-2 mt-5 text-neutral-700">
                                        <div className="text-xl font-medium tracking-tight">{x.name}</div>
                                        <div className="mt-2 text-black whitespace-pre-wrap">{x.description}</div>
                                        <div className="mt-5 text-sm font-medium">Pros</div>
                                        <ul className="ml-5 list-disc">
                                            {x.pros.map(y => <li className="text-black">{y}</li>)}
                                        </ul>
                                        <div className="mt-5 text-sm font-medium">Cons</div>
                                        <ul className="ml-5 list-disc">
                                            {x.cons.map(y => <li className="text-black">{y}</li>)}
                                        </ul>
                                    </div>)}

                                </Tab.Panel>

                                <Tab.Panel className="text-sm text-gray-500">
                                    <div className="mt-5 text-black whitespace-pre-wrap">{car.details}</div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>

                {/* Related products */}
                <div className="max-w-2xl mx-auto mt-24 sm:mt-32 lg:max-w-none">
                    <div className="flex items-center justify-between space-x-4">
                        <h2 className="text-lg font-medium text-gray-900">Other cars you may like</h2>
                        <Link to={'/our-electrifying-range'} className="text-sm font-medium text-black whitespace-nowrap hover:text-green-700">
                            View all
                            <span aria-hidden="true"> &rarr;</span>
                        </Link>
                    </div>
                    <div className="grid grid-cols-1 mt-6 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                        {data.cars.filter(x => x._id != car._id).filter((x, i) => i < 4).map((product) => (
                            <div key={product._id} className="relative text-sm bg-white rounded-lg shadow group">
                                <img className="absolute inset-0 w-full h-full p-5 pointer-events-none -top-8" src="/icon-green.svg"></img>
                                <div className="w-full overflow-hidden aspect-h-1 aspect-w-1">
                                    <ImageViewer
                                        image={product.thumbnail}
                                        alt={product.model}
                                        className="object-scale-down object-center w-full h-full "
                                    />
                                </div>

                                <div className="px-5 pb-5">
                                    <div className="relative flex items-end justify-between -top-5">
                                        <div>
                                            <h4 className="text-lg font-bold text-gray-900">{product.make}</h4>
                                            <h3 className="font-medium text-gray-700">{product.model}</h3>
                                            <p className="italic text-gray-500">{product.range} miles</p>
                                        </div>
                                        <div className="relative">
                                            <div className="relative text-xs text-gray-500 top-2">from</div>

                                            <div>
                                                <span className="text-3xl font-bold text-gray-900">£{product.monthlyPriceFrom}</span> <span className="text-gray-700">/mo</span>

                                            </div>

                                        </div>
                                    </div>

                                    <button
                                        onClick={() => navigate('/car/' + product._id)}
                                        type="button"
                                        className="w-full px-3 py-2 font-light tracking-wide text-white rounded-md shadow-sm cursor-pointer hover:bg-neutral-900 text-md bg-neutral-800"
                                    >
                                        Explore
                                    </button>


                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

        </div>
    )
}