import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../services/api.service'

export default function About() {
    const navigate = useNavigate()
    const [data, setData] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/about`).then((x) => setData(x))
    }, [])

    if (!data) return <></>
    return <div>
        <div className="mb-5 text-4xl font-black text-center mt-14">{data.title}</div>
        <div className="text-2xl font-semibold text-center">{data.bio}</div>

        <div className="mt-10 mb-5 text-xl font-medium text-center text-neutral-800">{data.subtitle}</div>
        <div className="text-lg text-center text-neutral-700">{data.body}</div>

        <div className="mt-10 mb-5 text-xl font-medium text-center text-neutral-800">{data.subtitle2}</div>
        <div className="text-lg text-center text-neutral-700">{data.body2}</div>

        <div className="mt-10 mb-5 text-xl font-medium text-center text-neutral-800">{data.subtitle3}</div>
        <div className="text-lg text-center text-neutral-700">{data.body3}</div>
    </div>
}